import React from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import { Mailchimp } from '../style/components/mailchimp'

class MailChimpForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      firstName: '',
      email: '',
      result: 'await',
      change: null,
      errorString: null,
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange(e) {
    this.setState({
      [`${e.target.name}`]: e.target.value,
    })
  }

  handleSubmit(e) {
    e.preventDefault()
    const { email, firstName } = this.state
    // console.log('this is the state')
    // console.log(this.state)
    addToMailchimp(email, {
      FNAME: firstName,
      EMAIL: email,
    })
      .then(({ msg, result }) => {
        // console.log('yay result')
        // console.log(msg, result)
        if (result !== 'success') {
          const change = msg.match(/https:[A-z./?=&0-9-%]*/g)
          if (change) {
            this.setState({ change: change[0] })
          } else {
            this.setState({ errorString: msg.replace(/[0-9] - /g, '') })
          }

          throw msg
        } else {
          this.setState({
            result: 'success',
            firstName: '',
            email: '',
            change: null,
            errorString: null,
          })
        }
      })
      .catch(() => {
        // console.log('in the failure')
        this.setState({ result: 'failure' })
      })
  }

  render() {
    const response = {
      await:
        "Drop your email in the box below and we'll let you know when we publish new stuff. We respect your email privacy, we will never spam you and you can unsubscribe anytime.",
      success: 'Signed up successfully (check email to confirm).',
      failure: 'Something went wrong, check if details are entered correctly.',
    }
    const { result, change, errorString } = this.state
    return (
      <Mailchimp>
        <h3> There is more where that came from! </h3>
        {change ? (
          <p>
            This email has already been added,{' '}
            <a href={change} style={{ color: '#442d6b' }}>
              click here to update.
            </a>
          </p>
        ) : errorString ? (
          <p>{errorString}</p>
        ) : (
          <p>{response[result]}</p>
        )}

        <form onSubmit={this.handleSubmit}>
          <input
            type="text"
            onChange={this.handleChange}
            name="firstName"
            id="firstName"
            placeholder="Your First Name"
          />

          <input
            type="email"
            onChange={this.handleChange}
            name="email"
            id="email"
            placeholder="Your Email"
          />
          <input type="submit" value="I want more, get me on the list!" />
        </form>
      </Mailchimp>
    )
  }
}

MailChimpForm.propTypes = {}

export default MailChimpForm
