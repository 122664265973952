/* Vendor imports */
import React from 'react'
import PropTypes from 'prop-types'
/* App imports */
import { Container } from '../style/components/post-article'

const Article = ({ html }) => (
  <Container>
    <article dangerouslySetInnerHTML={{ __html: html }} />
  </Container>
)

Article.propTypes = {
  html: PropTypes.string.isRequired,
}

export default Article
