import styled from 'styled-components'
import { darken } from 'polished'
import { heading, modifySize } from '../mixins'

import 'prismjs/themes/prism-solarizedlight.css'

export const LanguageLabelColors = ({ theme }, language, background, text) => {
  const setText = text ? text : theme['main-color']
  const selector = `~'.gatsby-highlight[data-language=${language}]::before'`

  return `
         ${selector} {
              background-color: ${background};
              color: ${setText};
          }
        
    `
}

export const Container = styled.div`
  ${({ theme }) => {
    return ` 
    ${LanguageLabelColors({ theme }, 'less', '#509f98')}
    ${LanguageLabelColors({ theme }, 'html', '#e34c26')}
    ${LanguageLabelColors({ theme }, 'css', '#563d7c')}
    ${LanguageLabelColors({ theme }, 'javascript', '#efbb35', '#000000')}
    ${LanguageLabelColors({ theme }, 'typescript', '#2b7489')}
    ${LanguageLabelColors({ theme }, 'php', '#4f5d95')}

    .gumroadMarketing{
        -webkit-appearance: button;
        -moz-appearance: button;
        appearance: button;
        text-align: center;

        width: 100%;
        margin: 10px 0;
        display: inline-block;
        border-radius: ${theme['border-radius-small']};
        font-size: 18px;
        box-sizing: border-box;
        padding: 16px;
        border-width: 0;
        background-color: ${theme['inverted-theme']}
        color: ${darken(0.5, theme['inverted-theme'])}
    }
    .gumroadMarketing:hover{
        background-color: ${darken(0.1, theme['inverted-theme'])}
        color: ${darken(0.6, theme['inverted-theme'])}
    }

    .gatsby-highlight {
        position: relative;
        &::before {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            padding: ${modifySize(theme['padding-small'], 1 / 2)} ${
      theme['padding-zero']
    };
            content: attr(data-language);
            text-indent: 15px;
            color: ${theme['main-color']};
            background:${theme['label-theme']};
        }
        &[data-language='text'],
        &[data-language='none'] {
            &::before {
            display: none;
            }
            pre {
            padding-top: ${theme['padding-medium']};
            }
        }
        pre {
            font-family: 'Roboto-Mono';
            font-size: ${modifySize(theme['font-size'], 0.9)};
            overflow-x: auto;
            overflow-y: hidden;
            padding-top: ${modifySize(theme['padding-medium'], 2.5)};
            background-color: ${theme['post-code']};
            border-radius: ${theme['border-radius-small']};
            code {
            font-family: inherit;
            line-height: 1.25em;
            background-color: inherit;
            }
        }
    }


    h1 {
        ${heading(
          { theme },
          modifySize(theme['font-size'], 2),
          theme['main-color']
        )};
    }
    h2 {
        ${heading(
          { theme },
          modifySize(theme['font-size'], 1.75),
          theme['main-color']
        )};
    }
    h3 {
        ${heading(
          { theme },
          modifySize(theme['font-size'], 1.5),
          theme['main-color']
        )};
    }
    article {
        overflow-x: auto;
        line-height: 1.6;
    }
    h1,
    h2,
    h3,
    strong,
    th {
        font-family: 'CustomBoldFont', sans-serif;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        text-align: left;
    }
    h1,
    h2 {
        border-bottom: 1px solid ${theme['color-transparent']};
    }
    p {
        code {
        white-space: pre-wrap !important;
        }
    }
    a {
        color: ${theme['post-highlight']};
        text-decoration: underline;
        word-break: break-all;
        code {
        text-decoration: inherit;
        }
    }
    figcaption {
        max-width: 1000px;
        margin: auto;
    }
    img{
        box-shadow: none !important;
    }
    blockquote {
        font-size: ${theme['font-size']} * 1.6;
        color: ${theme['post-highlight']};
        font-style: italic;
        font-weight: 200;
        @media ${theme['mobile']} {
        font-size: ${theme['font-size']} * 1.3;
        margin: ${theme['margin-medium']} ${theme['margin-zero']};
        }
        p::before,
        p::after {
        content: '"';
        }
    }
    iframe {
        display: block;
        margin: auto;
        @media ${theme['mobile']} {
        max-width: 300px;
        }
    }
    table {
        margin: auto;
        border-spacing: 0px;
        td,
        th {
        padding: ${theme['padding-small']} ${theme['padding-medium']};
        border: 1px solid ${theme['post-highlight']};
        }
        th {
        text-align: center;
        }
        thead {
        background-color: ${theme['post-highlight']};
        color: ${theme['post-highlight-text']};
        th,
        td {
            border-width: 1px 1px 0px 0px;
            &:first-child {
            border-left-width: 1px;
            }
        }
        }
        tbody {
        tr {
            th,
            td {
            border-width: 1px 1px 0px 0px;
            &:first-child {
                border-left-width: 1px;
            }
            }
            &:last-child th,
            &:last-child td {
            border-bottom-width: 1px;
            }
        }
        }

    }
    && :not(pre) > code[class*='language-'] {
        font-family: 'Roboto-Mono';
        padding: 0.1em;
        border-radius: ${theme['border-radius-small']};
        background-color: ${theme['post-highlight']};
        color: ${theme['post-highlight-text']};
        background-color: ${theme['label_theme']};
        a{
            color: ${theme['post-highlight-text']};
        }
    }

    # this is just to get a consistent highlight color for code blocks
    .gatsby-highlight {
        pre {
            code::selection {
                background-color: ${theme['post-highlight-text']};
            }
            
            code ::-moz-selection {
                background-color: ${theme['post-highlight-text']};
            }

            code[class*="language-"] ::selection {
                background: ${theme['post-highlight-text']} !important;
            }
            
            code[class*="language-"] ::-moz-selection {
                background: ${theme['post-highlight-text']} !important;
            }
        }
    }

 
    `
  }};
`
