/* Vendor imports */
import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
/* App imports */
import TagList from './tag-list'
import { Header, Title, Cover } from '../style/components/post-heading'

const Heading = ({ title, tags, cover, coverTitle }) => (
  <Header>
    <Title>
      <h1>{title}</h1>
      <TagList tags={tags} />
    </Title>
    <Cover>
      <Img fluid={cover} title={coverTitle} alt={title} />
    </Cover>
  </Header>
)

Heading.propTypes = {
  title: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
  cover: PropTypes.object.isRequired,
  coverTitle: PropTypes.string,
}

export default Heading
