import styled from 'styled-components'
import { flexCenter, frameMedium } from '../mixins'

export const Container = styled.div`
  ${({ theme }) => `
      ${flexCenter()}
      ${frameMedium({ theme })}
      > div:focus {
        outline: none;
      }
      @media ${theme['mobile']} {
        margin-top: ${theme['margin-small']};
        margin-bottom: ${theme['margin-zero']};
      }
    `};
`

export const Icon = styled.div`
  ${({ theme }) => `
    svg {
    height: 40px;
    width: 40px;
    margin-right: ${theme['margin-medium']};
    outline-color: transparent;
    transition: ${theme['transition-time']} opacity, ${
    theme['transition-time']
  } transform;
    &:hover {
        cursor: pointer;
        opacity: 0.8;
        transform: scale(1.2);
    }
    }

    `};
`
