import styled from "styled-components"
import { flexCenter, frameSmall, modifySize } from "../mixins"

export const Header = styled.div`
  ${({ theme }) => `
      ${flexCenter()}
      ${frameSmall({ theme })}
      flex-wrap: nowrap;
      flex-direction: column-reverse;
    `};
`

export const Title = styled.div`
  ${({ theme }) => `
    margin: auto;
    padding: ${theme["padding-small"]} ${modifySize(
    theme["padding-medium"],
    2
  )} ${theme["padding-small"]} ${theme["padding-small"]};
    text-align: left;
    h1 {
      text-shadow: ${theme["text-shadow-title"]};
    }
    @media ${theme["mobile"]}, ${theme["tablet"]} {
      padding: ${theme["padding-medium"]} ${theme["padding-small"]};
      h1 {
        margin-top: ${theme["margin-zero"]};
        margin-bottom: ${theme["margin-small"]};
      }
    }
  `};
`

export const Cover = styled.div`
  ${({ theme }) => `
    width: 80%;
    box-shadow: ${theme["post-cover-box-shadow"]};
    margin: auto;
    @media ${theme["mobile"]}, ${theme["tablet"]} {
      width: 100%;
    }
  `};
`
