import styled from 'styled-components'
import { modifySize } from '../mixins'

export const Container = styled.div`
  ${({ theme }) => `
    margin: auto;
    @media ${theme['desktop']} {
        width: 75%;
    }
    `};
`

export const Content = styled.div`
  ${({ theme }) => `
        background-color: ${theme['theme-color']};
        box-shadow: ${theme['post-article-box-shadow']};
        color: ${theme['main-color']};
        box-sizing: border-box;
        padding: ${theme['padding-medium']};
        @media ${theme['desktop']} {
            padding: ${modifySize(theme['padding-medium'], 2)} ${modifySize(
    theme['padding-medium'],
    4
  )};
        }
    `};
`
