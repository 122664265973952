import styled from "styled-components"
import { modifySize } from "../mixins"

export const Container = styled.div`
  ${({ theme }) => `
    display: flex;
    flex-direction: column;
    justify-content: left;
    label {
        width: 100%;
        strong {
        font-family: 'CustomBoldFont', sans-serif;
        }
        a {
        color: ${theme["post-highlight"]};
        text-transform: capitalize;
        transition: ${theme["transition-time"]} color ;
        text-decoration: underline;
        }
    }
    `};
`

export const Excerpt = styled.div`
  ${({ theme }) => `
    color: ${theme["post-highlight"]};
    font-size: ${modifySize(theme["font-size"], 1.3)};
    `};
`
