import styled from 'styled-components'
import { darken } from 'polished'

export const Mailchimp = styled.div`
  ${({ theme }) => `
    padding: 1% 10%;
    background-color: ${theme['label-theme']};
    text-align: center;
    border-radius: ${theme['border-radius-small']}
    h3, p{
        color: ${theme['post-highlight-text']}
    }
    form{
        display: block;

    }

    input{
        width: 100%;
        margin: 10px 0;
        display: inline-block;
        border-radius: ${theme['border-radius-small']};
        font-size: 18px;
        box-sizing: border-box;
        padding: 16px;
        color: ${theme['main-color']};
        border-width: 0;

    }
    input:focus, input:active{
        outline: none;
    }
    input[type=text], input[type=email]{
        color: ${theme['main-color']};
    } 
    input[type=submit]{
        background-color: ${theme['inverted-theme']}
        color: ${darken(0.5, theme['inverted-theme'])}
    }
    input[type=submit]:hover{
        background-color: ${darken(0.1, theme['inverted-theme'])}
        color: ${darken(0.6, theme['inverted-theme'])}
    }



    `}
`
