/* Vendor imports */
import React from 'react'
import { graphql } from 'gatsby'
/* App imports */
import Layout from '../components/layout'
import Seo from '../components/seo'
import Heading from '../components/post-heading'
import ArticleHeading from '../components/post-article-heading'
import Article from '../components/post-article'
// import Comments from '../components/post-comments'
import Share from '../components/post-share'
// import SuggestedPosts from '../components/post-suggested-posts'
import MailChimp from '../components/mailchimp'
import Config from '../../config'
import Utils from '../utils'
import { Container, Content } from '../style/components/post'

const Post = ({ data, pageContext }) => {
  const { html, frontmatter, timeToRead } = data.markdownRemark
  const { title, date, tags, cover, path, excerpt } = frontmatter
  const translations =
    pageContext.translations.length > 1 ? pageContext.translations : null
  const img = cover.childImageSharp.fluid
  const canonicalUrl = Utils.resolvePageUrl(
    Config.siteUrl,
    Config.pathPrefix,
    path
  )
  const coverUrl = Utils.resolveUrl(Config.siteUrl, img.src)
  // const suggestedPosts = Utils.getSuggestedPosts(
  //   data.markdownRemark,
  //   data.allMarkdownRemark,
  //   3
  // )

  return (
    <Layout>
      <Seo
        title={title}
        description={excerpt}
        path={path}
        contentType="article"
        imageUrl={img.src}
        keywords={tags}
        translations={translations}
      />
      <Container>
        <Heading title={title} tags={tags} cover={img} coverTitle={excerpt} />
        <Content>
          <ArticleHeading
            excerpt={excerpt}
            date={date}
            time={timeToRead}
            translations={translations}
          />
          <Article html={html} />
          <Share
            pageCanonicalUrl={canonicalUrl}
            title={title}
            description={excerpt}
            tags={tags}
            coverUrl={coverUrl}
          />
          <MailChimp />
        </Content>
        {/* <SuggestedPosts posts={suggestedPosts} /> */}
        {/* <Comments pageCanonicalUrl={canonicalUrl} pageId={title} /> */}
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($postPath: String!) {
    markdownRemark(frontmatter: { path: { eq: $postPath } }) {
      html
      timeToRead
      frontmatter {
        title
        date(formatString: "DD MMM YYYY")
        tags
        path
        excerpt
        cover {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    }
    allMarkdownRemark(
      filter: {
        frontmatter: { path: { ne: $postPath } }
        fileAbsolutePath: { regex: "/index.md$/" }
      }
    ) {
      edges {
        node {
          frontmatter {
            path
            title
            tags
            excerpt
            cover {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`
export default Post
